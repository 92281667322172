<template>
  <v-app>
    <div>
      <div class="header col-12">
        <router-link
          class="secondary btn"
          :to="{ path: '/', query: $route.query }"
          >Tilbage til oversigt</router-link
        >
      </div>
      <vehicleDisplay v-if="vehicle" :vehicle="vehicle" :key="vehicleId" />
    </div>
  </v-app>
</template>

<script>
import vehicleDisplay from "../components/vehicleDisplay";
// import { getVehicle } from "../services/database.ts";
import dataStorage from "../services/dataStorage.ts";

export default {
  name: "Vehicle",
  props: ["vehicleId"],
  components: {
    vehicleDisplay,
  },
  methods: {},
  data() {
    return {
      vehicle: null,
    };
  },
 
  async created() {
      this.vehicle = await dataStorage.vehicle(this.vehicleId);
    // pre-load vehicles
    await dataStorage.vehicles();
  },
  mounted() {
    //Update parent urlParam through widgetConnector
    //action: 11 = urlParam
    parent.postMessage({ action: 11, namespace: 'bt', text: {vehiclesearchid: this.vehicleId} }, dataStorage.getHost());
  },
  beforeDestroy() {
    //Update parent urlParam through widgetConnector
    //action: 11 = urlParam
    parent.postMessage({ action: 11, namespace: 'bt', text: {vehiclesearchid: null} }, dataStorage.getHost());
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>