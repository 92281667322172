<template>
  <div class="vehicleList">
      <div >
        <div v-for="vehicle in  vehicles" v-bind:key="vehicle.id" class="vehicleCards">
          <span @click="selectVehicle(vehicle)">
            <vehicleCard :vehicle="vehicle" />
          </span>
        </div>
      </div>
  </div>
</template>

<script>

import vehicleCard from "./vehicleCard";
import dataStorage from "../services/dataStorage.ts";

export default {
  name: 'vehicleList',
  components: {
    vehicleCard,
    // vehicleDisplay
  },
  data() {
    return {
      vehicles:null,
    }
  },
  methods: {
    selectVehicle(vehicle) {
      this.$router.push({ name: 'vehicle', params: { vehicleId: vehicle.documentId } })
    }
  },
  async created() {
    const $vehicles = await dataStorage.vehicles();
    
    if ($vehicles && $vehicles.length > 0 ) {
        this.vehicles = $vehicles;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.vehicleCards {
  display: inline-block;
  margin: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 5px 1px #bbbbbb;
  border-radius: 5px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
