<template>
  <div class="vehicleDisplay row">
    <button v-if="hasCarsPage" @click="goToWebpage">goToWebpage</button>
    <div class="imageWrapper col-sm-12 col-md-6">
      <img :src="vehicle.images[0]" />
    </div>
    <div class="tileWrapper col-sm-12 col-md-6">
      <div class="propertyTile tile row">
        <div class="title">{{ vehicle.makeName }} {{ vehicle.model }}</div>
        <div>{{ vehicle.variant }}</div>
        <div
          v-if="price"
          class="property col-xs-12 col-sm-6 col-md-12 col-lg-6"
        >
          <span class="name">Kontantpris</span
          ><span class="value">{{ price.valueFormatted }}</span>
        </div>
        <div
          v-if="financing"
          class="property col-xs-12 col-sm-6 col-md-12 col-lg-6"
        >
          <span class="name">{{ financing.publicName }}</span
          ><span class="value">{{ financing.valueFormatted }}</span>
        </div>
        <div
          v-if="color"
          class="property col-xs-12 col-sm-6 col-md-12 col-lg-6"
        >
          <span class="name">{{ color.publicName }}</span
          ><span class="value">{{ color.value }}</span>
        </div>
        <div
          v-if="modelYear"
          class="property col-xs-12 col-sm-6 col-md-12 col-lg-6"
        >
          <span class="name">{{ modelYear.publicName }}</span
          ><span class="value">{{ modelYear.value }}</span>
        </div>
        <div
          v-if="mileage"
          class="property col-xs-12 col-sm-6 col-md-12 col-lg-6"
        >
          <span class="name">{{ mileage.publicName }}</span
          ><span class="value">{{ mileage.valueFormatted }}</span>
        </div>
        <div v-if="kmxl" class="property col-xs-12 col-sm-6 col-md-12 col-lg-6">
          <span class="name">{{ kmxl.publicName }}</span
          ><span class="value">{{ kmxl.valueFormatted }}</span>
        </div>
      </div>
    </div>
    <div class="tileWrapper col-12">
      <div class="descriptionTile tile">
        <h2>Beskrivelse</h2>
        {{ vehicle.description }}
      </div>
    </div>
    <div class="tileWrapper col-sm-12 col-lg-6">
      <div class="equipmentTile tile">
        <h2>Udstyr</h2>
        <div v-if="vehicle.equipment.length > 0">
          Udstyrsvisning ikke implementeret endnu
        </div>
        <div v-else>Intet udstyr angivet</div>
      </div>
    </div>
    <div class="tileWrapper col-sm-12 col-lg-6">
      <div class="specificationsTile tile">
        <h2>Specifikationer</h2>
        <div class="row">
          <h3>Alder og kilometerstand</h3>
          <div v-if="mileage" class="specification col-6 col-sm-4">
            <span class="name">{{ mileage.publicName }}</span
            ><span class="value">{{ mileage.valueFormatted }}</span>
          </div>
          <div v-if="modelYear" class="specification col-6 col-sm-4">
            <span class="name">{{ modelYear.publicName }}</span
            ><span class="value">{{ modelYear.value }}</span>
          </div>
          <div v-if="firstRegYear" class="specification col-6 col-sm-4">
            <span class="name">{{ firstRegYear.publicName }}</span
            ><span class="value">{{ modelYear.value }}</span>
          </div>
        </div>
        <div class="row">
          <h3>Motor og ydelse</h3>
          <div v-if="vehicle.propellant" class="specification col-6 col-sm-4">
            <span class="name">Drivmiddel</span
            ><span class="value">{{ vehicle.propellant }}</span>
          </div>
        </div>
        <div class="row">
          <h3>Sikkerhed og økonomi</h3>
          <div v-if="kmxl" class="specification col-6 col-sm-4">
            <span class="name">{{ kmxl.publicName }}</span
            ><span class="value">{{ kmxl.valueFormatted }}</span>
          </div>
        </div>
        <div class="row">
          <h3>Rummelighed og mål</h3>
          <div v-if="color" class="specification col-6 col-sm-4">
            <span class="name">{{ color.publicName }}</span
            ><span class="value">{{ color.value }}</span>
          </div>
          <div v-if="bodytype" class="specification col-6 col-sm-4">
            <span class="name">{{ bodytype.publicName }}</span
            ><span class="value">{{ bodytype.value }}</span>
          </div>
        </div>
        <div class="row">
          <h3>Penge</h3>
          <div v-if="price" class="specification col-6 col-sm-4">
            <span class="name">Pris</span
            ><span class="value">{{ price.valueFormatted }}</span>
          </div>
          <div v-if="financing" class="specification col-6 col-sm-4">
            <span class="name">{{ financing.publicName }}</span
            ><span class="value">{{ financing.valueFormatted }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataStorage from "../services/dataStorage.ts";

export default {
  name: "vehicleDisplay",
  components: {},
  props: {
    vehicle: Object,
  },
  data() {
    return {
      hasCarsPage: false,
    };
  },
  methods: {
    deselectVehicle() {
      this.$emit("deselectVehicle");
    },
    goToWebpage() {
      const host = dataStorage.getHost();
      
      const carslinkpage = dataStorage.getParaData().carspage;
      

      if (carslinkpage) {
        parent.postMessage(
          {
            action: 7,
            namespace: "bt",
            data: { url: carslinkpage + this.vehicle.documentId },
          },
          host
        );
      }
    },
  },
  computed: {
    price() {
      return this.vehicle.properties.find((x) => x.id === "Price");
    },
    financing() {
      return this.vehicle.properties.find(
        (x) => x.id === "FinancingMonthlyPrice"
      );
    },
    color() {
      return this.vehicle.properties.find((x) => x.id === "Color");
    },
    mileage() {
      return this.vehicle.properties.find((x) => x.id === "Mileage");
    },
    kmxl() {
      return this.vehicle.properties.find((x) => x.id === "Kmx1l");
    },
    modelYear() {
      return this.vehicle.properties.find((x) => x.id === "ModelYear");
    },
    firstRegYear() {
      return this.vehicle.properties.find((x) => x.id === "FirstRegYear");
    },
    bodytype() {
      return this.vehicle.properties.find((x) => x.id === "BodyType");
    },
  },
  created() {
    const paraData = dataStorage.getParaData();
    if (paraData.carspage) {
      this.hasCarsPage = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vehicleDisplay {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  padding-bottom: 25px;

  .header {
    text-align: left;
  }

  .tileWrapper {
    padding: 5px;

    .tile {
      //TODO: use theme
      background: inherit;

      //TODO: use theme
      border: 1px black;
      border-style: solid;
      border-radius: inherit;

      border-radius: 5px;
      padding: 10px;
    }
  }

  .imageWrapper {
    display: inline-block;

    img {
      height: auto;
      width: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .propertyTile {
    display: inline-block;
    width: 100%;

    .property {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;

      display: inline-flex;
      justify-content: space-between;

      //xs
      @media (max-width: 575px) {
        span {
          display: block;
        }
      }
      //md
      @media (min-width: 768px) and (max-width: 991px) {
        span {
          display: block;
        }
      }
    }
  }

  .specification {
    display: inline-block;
    padding: 5px;
    padding-left: 10px;
    text-align: left;

    span {
      display: block;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  h3 {
    font-size: 22px;
  }
}
</style>
