<template>
  <v-container class="textcolor--text">
    <v-row>
      <v-col>
        <v-text-field
          v-model="$vuetify.theme.themes.light.bgprimary"
          label="bg primary"
          @input="textcolorInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="$vuetify.theme.themes.light.bgsecondary"
          label="bg secondary"
          @input="textcolorInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="$vuetify.theme.themes.light.textcolor"
          label="text color"
          @input="textcolorInput"
        ></v-text-field>
      </v-col> 
      <v-col>
        <v-text-field
          v-model="$vuetify.theme.themes.light.textcolorsecondary"
          label="text color secondary"
          @input="textcolorInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="$vuetify.theme.themes.light.btncolor"
          label="btn color"
          @input="textcolorInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="$vuetify.theme.themes.light.btntextcolor"
          label="btn text color"
          @input="textcolorInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <pre class="bgprimary--text bgsecondary">
           {{ $vuetify.theme.themes.light }}
        </pre>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="vehicleCards bgprimary textcolorsecondary--text">
        <vehicleCard :vehicle="vehicle" class="bgsecondary textcolorsecondary--text" />
        <vehicleCard :vehicle="vehicle" class="bgsecondary ml-5 textcolorsecondary--text" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>button</h1>
        <v-btn color="btncolor btntextcolor--text">btncolor</v-btn>
      </v-col>
      <v-col>
        <h1>Typography</h1>
        <v-card class="textcolor--text bgprimary">
          <div class="text-h1">Heading 1</div>
          <div class="text-h2">Heading 2</div>
          <div class="text-h3">Heading 3</div>
          <div class="text-h4 mb-2">Heading 4</div>
          <div class="text-h5">Heading 5</div>
          <div class="text-h6">Heading 6</div>
          <div class="text-subtitle-1">Subtitle 1</div>
          <div class="text-subtitle-2">Subtitle 2</div>
          <div class="text-body-1">Body 1</div>
          <div class="text-body-2">Body 2</div>
          <div class="text-button">Button</div>
          <div class="text-caption">Caption</div>
          <div class="text-overline">Overline</div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vehicleCard from "./vehicleCard";
export default {
  name: "theme",

  components: {
    vehicleCard,
  },
  data() {
    return {
      vehicle: {
        equipment: [],
        additionalEquipment: [],
        company: {
          id: 5665,
          name: "Biltorvet WEB - Demo 2",
          address: "Haslevgade 10",
          city: "Taastrup",
          postNumber: 2630,
          email: null,
          phone: null,
          coordinates: {
            longitude: "12.270141025293881",
            latitude: "55.65073591139046",
          },
        },
        videos: null,
        documentId: "AD2016710",
        id: 2016710,
        makeName: "Volvo",
        makeId: 57,
        model: "V90",
        modelId: -1,
        variant: "2,0 T6 Inscription AWD 320HK Stc 8g Aut.",
        automatic: true,
        propellant: "Benzin",
        description:
          "Lorem ipsum dolor sit amet, ipsum interdum maecenas mauris nullam, mi animi sodales. Auctor tempus vitae risus urna in enim, nulla sollicitudin eget. Mi volutpat, ut sit perferendis elit, phasellus proin, nunc dolor tortor, ante augue. Diam velit culpa, sed massa aenean phasellus amet voluptatibus mauris. Pellentesque vehicula in nam.\n\nAnte mollis id, in vivamus tristique sed ultrices quis euismod, imperdiet non purus facilisis libero tempor, mus placerat mauris, ut nibh. Fermentum libero, eros sodales, sit turpis sed sapien nec mauris amet, sed aliquam. Lacus dolor velit integer quisque, consectetuer libero justo ultrices libero, tellus neque sed elit. Adipiscing dolor ipsum mauris interdum semper eleifend, blandit vestibulum tempus in mauris magna. Duis morbi gravida purus quisque, donec aenean eu est at laoreet leo, similique lacus nunc ligula vitae pellentesque. Nulla vitae sed, dolor pede mollis magna. Ac et facilisis aenean, varius donec nemo, quam donec nec malesuada id magna pretium, eros vestibulum, eros wisi ultrices purus nunc pharetra. Et elit, erat donec, vestibulum feugiat quam tortor, suspendisse eget curabitur leo purus faucibus. Justo ut nobis sollicitudin, ac etiam sapien donec sed, et placerat sem morbi at, primis adipiscing, integer enim metus integer quisque. Arcu conubia, phasellus nibh elit quisque auctor cursus, ligula blandit cubilia maecenas nibh et, vivamus pellentesque posuere vivamus lacinia ornare massa. Velit nunc justo arcu hac aliquam odio, risus torquent magnis tortor eu, quam a aenean odio et. Nam blandit suscipit amet urna posuere pretium, nulla enim ut eu.",
        energyClass: "D",
        type: "Personbil",
        typeId: 1,
        brandNew: false,
        images: [
          "https://imageapisecure.autoit.dk/ImageService.svc/GetVehicleImageFromImageId/2657731/1024",
        ],
        headerImages: null,
        properties: [
          {
            id: "Mileage",
            value: "120000",
            valueFormatted: "120.000",
            publicName: "Kilometertal",
            isPublic: true,
            group: "AgeAndState",
          },
          {
            id: "ModelYear",
            value: "2017",
            valueFormatted: null,
            publicName: "Model årgang",
            isPublic: true,
            group: "AgeAndState",
          },
          {
            id: "FirstRegYear",
            value: null,
            valueFormatted: null,
            publicName: "Første reg. år",
            isPublic: true,
            group: "AgeAndState",
          },
          {
            id: "Kmx1l",
            value: "13,5",
            valueFormatted: "13,5km/l",
            publicName: "Km/L",
            isPublic: true,
            group: "SecurityAndEconomy",
          },
          {
            id: "BodyType",
            value: "Stationcar",
            valueFormatted: null,
            publicName: "Karosseri",
            isPublic: true,
            group: "DimensionsAndParameters",
          },
          {
            id: "Color",
            value: "Sølv",
            valueFormatted: null,
            publicName: "Farve",
            isPublic: true,
            group: "DimensionsAndParameters",
          },
          {
            id: "VAT",
            value: "Nej",
            valueFormatted: null,
            publicName: "Eks. Moms",
            isPublic: true,
            group: "Finances",
          },
          {
            id: "Price",
            value: "375000",
            valueFormatted: "375.000,-",
            publicName: "Pris (Lev. omkostninger ukendte)",
            isPublic: true,
            group: "Finances",
          },
        ],
        labels: [{ key: 466, value: "RentLog - ekstern" }],
        updated: "2021-06-21T11:17:16.757",
        created: "2018-09-06T09:53:42.74",
        uri: "volvo/v90-20-t6-inscription-awd-320hk-stc-8g-aut/AD2016710",
        companyId: 5665,
        vehicleCardImage:
          "https://imageapisecure.autoit.dk/ImageService.svc/GetVehicleImageFromImageId/2657731/330",
        vin: "TANG0XGCABSU61234",
      },
    };
  },
  methods: {
    textcolorInput() {
      this.$vuetify.theme.applyTheme();
    },
  },
};
</script>
<style scoped>
.vehicleCards {
  display: inline-block;
  margin: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 5px 1px #bbbbbb;
  border-radius: 5px;
}
</style>