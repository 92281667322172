import { getData } from './parameters.ts';

export function applyTheme(theme) {
    const bgprimarycolor = getData().bgprimarycolor || "#fff";
    const bgsecondarycolor = getData().bgsecondarycolor || "#f0f0f0";
    const textcolorprimary = getData().textcolorprimary || "#000";
    const textcolorsecondary = getData().textcolorsecondary || "#000";
    const btncolor = getData().btncolor || "#3c64ff";
    const btntextcolor = getData().btntextcolor || "#fff";

    if (bgprimarycolor) {
        theme.themes.light.primary = bgprimarycolor;
        theme.themes.light.bgprimary = bgprimarycolor;
    }

    if (bgsecondarycolor) {
      theme.themes.light.secondary = bgsecondarycolor;
      theme.themes.light.bgsecondary = bgsecondarycolor;
    }

    if (textcolorprimary) {
        theme.themes.light.textcolor = textcolorprimary;
    }

    if (textcolorsecondary) {
        theme.themes.light.textcolorsecondary = textcolorsecondary;
    }

    if (btncolor) {
        theme.themes.light.btncolor = btncolor;
    }

    if (btntextcolor) {
        theme.themes.light.btntextcolor = btntextcolor;
    }

    theme.applyTheme();
}
