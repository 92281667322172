import { getAppHost } from "./parameters.ts";
import { getVehicles, getVehicle } from "./database.ts";


function getHost() {
    return localStorage.getItem('host');
}
function setHost() {
    
    localStorage.setItem("host", getAppHost());
}

async function vehicles() {
    const $vehicles = localStorage.getItem('vehicles');
    if (!$vehicles || $vehicles.length <= 0) {
        const vehicles = (await getVehicles()).vehicles;
        localStorage.setItem('vehicles', JSON.stringify(vehicles));
        return vehicles;
    } else {
        return JSON.parse($vehicles);
    }
}

function vehicles_store() {
    const $vehicles = localStorage.getItem('vehicles');
    return JSON.parse($vehicles);
}

async function vehicle(vehicleId) {
    let id = "vehicle_" + vehicleId;
    const $vehicle = localStorage.getItem(id);
    if ($vehicle) {
        return JSON.parse($vehicle);
    } else {
        const $vehicles = vehicles_store();
        let vehicle = null;
        if ($vehicles && $vehicles.length > 0) {
            vehicle = $vehicles.find(x => x.documentId == vehicleId)
        }
        if (!vehicle ) {
            vehicle = await getVehicle(vehicleId);
            localStorage.setItem(id, JSON.stringify(vehicle));
        }
        return vehicle;
    }
}

function setParaData(data) {
    localStorage.setItem("paraData", JSON.stringify(data));
}

function getParaData() {
    const paraData = localStorage.getItem("paraData");
    return JSON.parse(paraData)
}

export default {
    getHost,
    setHost,
    vehicles,
    vehicle,
    getParaData,
    setParaData
}