<template>
  <div class="vehicleCard">
    <div class="imageWrapper">
      <img :src="vehicle.images[0]" />
    </div>
    <div class="titleBox">
      {{vehicle.makeName}} {{vehicle.model}}
    </div>
    <div v-if="price" class="PriceBox">
      <span> {{price}} </span>
    </div>
    <div class="textBox">
      <div class="columnLeft">
        <div v-if="color"> {{color}} </div>
        <div v-if="year"> {{year}} </div>
      </div>
      <div class="columnRight">
        <div v-if="vehicle.propellant"> {{vehicle.propellant}} </div>
        <div v-if="mileage"> {{mileage}} km </div>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  name: 'vehicleCard',
  props: {
    vehicle: Object
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
    price() {
      return this.vehicle.properties.find((x) => x.id === 'Price').valueFormatted;
    },
    color() {
      return this.vehicle.properties.find((x) => x.id === 'Color').value;
    },
    year() {
      return this.vehicle.properties.find((x) => x.id === 'ModelYear').value;
    },
    mileage() {
      return this.vehicle.properties.find((x) => x.id === 'Mileage').valueFormatted;
    }
  }
  
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.vehicleCard {
  width: 350px;
  height: 275px;
  display: inline-block;

  //TODO: use theme
  border: 1px black;
  border-style: solid;
  border-radius: inherit;


  img {
    width: 100%;
    height: 185px;
    object-fit: cover;

    mask-image: linear-gradient(to top, transparent 10%, black 35%);
    -webkit-mask-image: linear-gradient(to top, transparent 10%, black 35%);

    transition: 0.2s;
  }

  &:hover img {
    transform: scale(1.05);
  }

  .textBox {
    .columnLeft {
      width: 50%;
      float: left;
      text-align: left;
      padding-left: 15px;
    }
    .columnRight {
      width: 50%;
      float: right;
      text-align: right;
      padding-right: 15px;
    }
  }

  .titleBox {
    position: relative;
    transform: translate(0px, -1.25rem);
    font-size: 18px;
    height: 0px;
    overflow: visible;
  }

  .priceBox {
    font-size: 18px;
  }

}

</style>