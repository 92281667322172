<template>
  <v-app>
    <transition name="slide-fade">
      <router-view />
    </transition>
  </v-app>
</template>
<script>
import { applyTheme } from "./services/theme.ts";
import { getData } from "./services/parameters.ts";
import dataStorage from "./services/dataStorage.ts";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  beforeCreate() {
    dataStorage.setHost();
    dataStorage.setParaData(getData());
    // window.parent.location
    applyTheme(this.$vuetify.theme);
    var vehicleId = dataStorage.getParaData().vehicleid;
    if (vehicleId) {
      this.$router.push({ name: "vehicle", params: { vehicleId: vehicleId } });
    }
  },
  created() {
  },
};
</script>

<style >
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>