<template>
  <v-app>
    <div id="vehicleSearchWidget">
      <!-- <vehicleSearch :searchFields="['Age', 'Propellant', 'Model']" /> -->
      <vehicleList />
      <!-- <vehicleDisplay /> -->
    </div>
  </v-app>
</template>

<script>
// import vehicleSearch from "./components/vehicleSearch";
import vehicleList from "../components/vehicleList";
// import vehicleDispay from "./components/vehicleDisplay";

export default {
  name: 'Home',
  components: {
    vehicleList,
  },
  data() {
    return {
    }
  },
  created() {
     
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#vehicleSearchWidget {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>