import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Theme from '../views/Theme.vue';
import Vehicle from '../views/Vehicle.vue';


Vue.use(VueRouter);
const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/theme',
      name: 'theme',
      component: Theme
    },
    {
      path: '/vehicle/:vehicleId',
      name: 'vehicle',
      component: Vehicle,
      props: true
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });
  
  export default router;