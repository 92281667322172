import { getData } from './parameters.ts';

const axios = require('axios').default;
const apiUrl = 'https://api-v1.biltorvet.as';
const id = `a=${getData().dataguid}`;

// function getFakeGUID() {
//     return 'ce760c3b-2d44-4037-980b-894b79891525'
// }

export async function getFilters() {
    const data = await axios.get(`${apiUrl}/vehicle/filteroptions?${id}`)
    return data.data.result;
}

export async function getModelFromMake(make) {
    const data = await axios.get(`${apiUrl}/vehicle/make/${make}?${id}`)
    return data.data.result;
}

export async function getVehicles() {   
    const data = await axios.get(`${apiUrl}/vehicle?${id}`);
    return data.data.result;
}

export async function getVehicle(vehicleId) {   
    const data = await axios.get(`${apiUrl}/vehicle/detail/${vehicleId}?${id}`);
    return data.data.result;
}