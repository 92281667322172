//TODO: Add better error handling for badly formatted data

function getContentDataFromURI() {
    const uri = window.location.search;
    const contentData = new URLSearchParams(uri).get('contentdata');
    return contentData;
}
const getParameters = () => {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    return params;
  }

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    // https://stackoverflow.com/a/30106551
    return decodeURIComponent(
        atob(str)
        .split('')
        .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    )
}

export function getAppHost() {
    const fallback = '';
    const params = getParameters().get('host');
    let parameters = params !== null ? params : fallback;
    return parameters;
  }
  

export function getData() {
    const params = getContentDataFromURI();
    let parameters = params ? b64DecodeUnicode(params) : null;
    return JSON.parse(parameters);
}

// Data we expect to get in 
// Colors
// Id for use in calling endpoint