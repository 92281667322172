<template>
      <theme  />
</template>

<script>
import theme from "../components/theme";

export default {
  name: 'Theme',
  components: {
    theme
  },
  data() {
    return {
    }
  },
  created() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#vehicleSearchWidget {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>